<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="importexport_xiaoshou">
        <div class="box">
            <p class="daoru_daochu_tit">
                <span @click="dianji_fanhui">返回</span>
            </p>
            <!-- <ul class="daoru_daochu_con_tit">
                <li v-for="(i,index) in daoru_daochu_list" :key="index" @click="daoru_or_daochu=index" :style="daoru_or_daochu==index?'border:0.01rem solid #f96c6c;background:#f96c6c;color:#fff;':''">{{i}}</li>
            </ul> -->
            <div class="daoru_daochu_con">
                <div class="daoru_daochu_con_zhanshi">
                    <div style="margin:0 auto;">
                        <p class="con_tit">{{daoru_or_daochu==0?xiazai_moban.name2:shangchuan_moban.name2}}</p>
                        <img class="daoru_daochu_tu" src="../../assets/daoru.png" alt="">
                        <!-- <p class="con_tishi">仅支持xls、xlsx格式文件</p> -->
                        <!-- <el-upload
                            class="upload-demo"
                            ref="upload"
                            action=''
                            :file-list="fileList"
                            :multiple="false"
                            :auto-upload="false">
                            <el-button style="background: #F96C6C;border:none;margin-left:0.15rem;margin-right:0.3rem;" slot="trigger" size="small" type="primary">选取文件</el-button>
                        </el-upload> -->
                        <p style="text-align:center;margin:0.06rem 0;">支持.jpg,.png,.pdf,.bmp,.jpeg,.doc,.docx,.xls,.xlsx文件</p>
                        <div class="fujian_anniu">
                            <input type="file" @change="getImgBasea()">
                            <p>选择文件</p>
                        </div>
                        <ul class="fujian_liebiao">
                            <li v-for="(i,index) in wenjian_list" :key="index"><p>{{i.name}}</p> <i @click="quchu_xuanze_wenjian(index)" class="el-icon-error"></i></li>
                        </ul>
                        <span class="con_btn" @click="uploadFile">上传文件</span>
                    </div>
                    <!-- <div>
                        <p class="con_tit">{{xiazai_moban.name1}}</p>
                        <img class="daoru_daochu_tu" src="../../assets/mobanxiazai.png" alt="">
                        <span class="con_btn" @click="moban_xiazai()">下载文件</span>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="importexport_beijing" v-if="zhuangtai" @click="zhuangtai=false">
            <div class="importexport_beijing_box" @click.stop>
                <p>
                    <i class="el-icon-warning"></i>成功导入{{chenggong}}条客户信息，失败{{shibai}}条
                </p>
                <p v-if="cuowu_wenjian.length!=0">
                    {{cuowu_wenjian}}，文件格式不正确
                </p>
                <p>
                    <span @click="zhuangtai=false">我知道了</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { upload_stock_mng_board_fj, get_con_annex, upload_sale_mng_board_fj } from '../../api/api.js'
import axios from '../../api/ajax'
export default {
  name: 'importexport_xiaoshou',
  data () {
    return {
      // daoru_daochu_list:['批量导入客户','导出/修改客户'],
      daoru_or_daochu: 0,
      fileList: [],
      chenggong: '0',
      shibai: '0',
      cuowu_wenjian: '',
      zhuangtai: false,
      date: {},
      xiazai_moban: {
        name1: '',
        name2: ''
      },
      shangchuan_moban: {
        name1: '',
        name2: ''
      },
      wenjian: '',
      wenjian_list: []
    }
  },
  mounted () {
  },
  created () {
    const ce = ''
    console.log()
    this.jichu()
  },
  methods: {
    jichu () {
      this.date = {}
      const date = this.$bendi_naqu_cunchu('mlbb_xiaoshou_tian', '暂时')
      this.date = date
      console.log(date)
      this.xiazai_moban.name1 = '下载' + date.name + '文件'
      this.xiazai_moban.name2 = '上传' + date.name + '文件'
      this.shangchuan_moban.name1 = '1、导出' + date.name + '文件'
      this.shangchuan_moban.name2 = '2、上传修改好的' + date.name + '文件'
    },
    dianji_fanhui () {
      window.history.go(-1)
    },
    //   下载多个文件
    multiDownLoad (val) {
      for (let i = 0; i < val.length; i++) {
        const type = val[i].substring(val[i].lastIndexOf('.'))
        const url = window.URL.createObjectURL(new Blob([val[i]]))
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = val[i]
        link.setAttribute('download', 'newfile')
        document.body.appendChild(link)
        link.click()
        setTimeout(res => {
          Link.remove()
        }, 5 * 60 * 1000
        )
        // document.body.removeChild(Link);
        // if(type=='.doc'||type=='.docx'){
        //     const iframe = document.createElement("iframe");
        //     iframe.style.display = "none";
        //     iframe.style.height = 0;
        //     iframe.src = val[i];
        //     document.body.appendChild(iframe);
        //     setTimeout(res => {
        //         iframe.remove()
        //         }, 5 * 60 * 1000
        //     );
        // }else{
        //     this.downloadIamge(val[i],'photo',type.substring(1))
        // }
      }
    },
    downloadIamge (imgsrc, name, type) { // 下载图片地址和图片名
      var image = new Image()

      // 解决跨域 Canvas 污染问题
      image.setAttribute('crossOrigin', 'anonymous')
      image.onload = function () {
        var canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        var context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        var url = canvas.toDataURL('image/png') // 得到图片的base64编码数据

        var a = document.createElement('a') // 生成一个a元素
        var event = new MouseEvent('click') // 创建一个单击事件
        a.download = name || 'photo' // 设置图片名称
        a.href = url // 将生成的URL设置为a.href属性
        a.dispatchEvent(event) // 触发a的单击事件
      }
      //  ;
      image.src = imgsrc
    },
    // downs(){
    //     this.downloadIamge('https://www.mlcbr.com/images/ff39d2323f3846d76855c21036dd45e1/20200513190707893_081051.jpg', 'photo')
    // },
    // 模板下载
    moban_xiazai () {
      get_con_annex({
        data: {
          ent_id: this.$ent_id(),
          file_no: this.date.date.file_no
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          console.log(date.con_url.split(' '))
          let data = ''
          if (this.date.name == '合同') {
            data = date.con_url.split(' ')
          } else if (this.date.name == '交付') {
            data = date.deliver_url.split(' ')
          } else if (this.date.name == '发票') {
            data = date.inv_url.split(' ')
          } else if (this.date.name == '回款') {
            data = date.rec_url.split(' ')
          }
          this.multiDownLoad(data)
          // this.downs()
        } else if (res.data.code == 500) {}
      })
      // let data=['https://www.mlcbr.com/hwb_client/V1.0.0/export_customer_template?ent_id='+this.$ent_id()]
      // this.multiDownLoad(data)
    },
    // 上传文件
    getImgBasea () {
      var event = event || window.event
      var file = event.target.files[0]
      console.log(file)
      var reader = new FileReader()
      const lei = file.name.substring(file.name.lastIndexOf('.'), file.name.length)
      // 转base64
      if (lei == '.doc' || lei == '.docx' || lei == '.pdf' || lei == '.jpg' || lei == '.gif' || lei == '.png' || lei == '.bmp' || lei == '.jpeg' || lei == '.xls' || lei == '.xlsx') {
        const arr = []
        const xin = ''
        var _this = this
        reader.onload = function (e) {
          arr.push(e.target.result)
          _this.xin = e.target.result.substring(
            e.target.result.indexOf(',') + 1,
            e.target.result.length
          )
          const obj = {
            xin: _this.xin,
            lei: lei
          }
          _this.wenjian_list.push({
            name: file.name,
            wenjian: obj
          })
          // _this.$bendi_cunfang_cunchu('mlbb_me_touxiang',obj,'暂时')
          _this.wenjian = obj
        }
        reader.readAsDataURL(file)
        _this.imgBase64 = arr
      } else {
        this.$message({
          message: '请上传上述描述的文件',
          type: 'warning'
        })
      }
    },
    uploadFile () {
      if (this.wenjian_list.length != 0) {
        const promis = {
          // onUploadProgress: progressEvent => {
          //     var complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
          //     // this.text = complete + '%'
          //     console.log(complete + '%')
          // }
          onUploadProgress (progress) {
            console.log(Math.round(progress.loaded / progress.total * 100) + '%')
          }
        }
        if (this.date.name == '合同' ||
                    this.date.name == '交付' ||
                    this.date.name == '发票' ||
                    this.date.name == '回款'
        ) {
          axios({
            method: 'post',
            url: '/upload_sale_mng_board_fj',
            data: {
              data: {
                ent_id: this.$ent_id(),
                user_id: this.$jichuxinxi().user_id,
                file_no: this.date.date.file_no,
                con_name: this.date.name == '合同' ? this.wenjian_list.map(item => item.name).join(',') : null,
                con_data: this.date.name == '合同' ? this.wenjian_list.map(item => item.wenjian.xin).join(' ') : null,
                con_type: this.date.name == '合同' ? this.wenjian_list.map(item => item.wenjian.lei).join(' ') : null,
                deliver_name: this.date.name == '交付' ? this.wenjian_list.map(item => item.name).join(',') : null,
                deliver_data: this.date.name == '交付' ? this.wenjian_list.map(item => item.wenjian.xin).join(' ') : null,
                deliver_type: this.date.name == '交付' ? this.wenjian_list.map(item => item.wenjian.lei).join(' ') : null,
                inv_name: this.date.name == '发票' ? this.wenjian_list.map(item => item.name).join(',') : null,
                inv_data: this.date.name == '发票' ? this.wenjian_list.map(item => item.wenjian.xin).join(' ') : null,
                inv_type: this.date.name == '发票' ? this.wenjian_list.map(item => item.wenjian.lei).join(' ') : null,
                rec_name: this.date.name == '回款' ? this.wenjian_list.map(item => item.name).join(',') : null,
                rec_data: this.date.name == '回款' ? this.wenjian_list.map(item => item.wenjian.xin).join(' ') : null,
                rec_type: this.date.name == '回款' ? this.wenjian_list.map(item => item.wenjian.lei).join(' ') : null
              }
            },
            onUploadProgress: progressEvent => {
              var complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
              // this.text = complete + '%'
              console.log(complete + '%')
            }
          }).then(res => {
            console.log(res)
            console.log(this.wenjian_list.map(item => item.wenjian.xin))
            if (res.data.code == 200) {
              this.$message({
                message: '上传成功',
                type: 'success'
              })
            } else if (res.data.code == 500) {}
          })
        } else {
          console.log(this.date.date)

          axios({
            method: 'post',
            url: '/upload_stock_mng_board_fj',
            data: {
              data: {
                ent_id: this.$ent_id(),
                user_id: this.$jichuxinxi().user_id,
                supply_id: this.date.date.supply_id,
                pur_name: this.date.name == '采购合同' ? this.wenjian_list.map(item => item.name).join(',') : null,
                pur_data: this.date.name == '采购合同' ? this.wenjian_list.map(item => item.wenjian.xin).join(' ') : null,
                pur_type: this.date.name == '采购合同' ? this.wenjian_list.map(item => item.wenjian.lei).join(' ') : null,
                pur_inv_name: this.date.name == '采购发票' ? this.wenjian_list.map(item => item.name).join(',') : null,
                pur_inv_data: this.date.name == '采购发票' ? this.wenjian_list.map(item => item.wenjian.xin).join(' ') : null,
                pur_inv_type: this.date.name == '采购发票' ? this.wenjian_list.map(item => item.wenjian.lei).join(' ') : null,
                in_store_name: this.date.name == '入库单' ? this.wenjian_list.map(item => item.name).join(',') : null,
                in_store_data: this.date.name == '入库单' ? this.wenjian_list.map(item => item.wenjian.xin).join(' ') : null,
                in_store_type: this.date.name == '入库单' ? this.wenjian_list.map(item => item.wenjian.lei).join(' ') : null,
                payment_name: this.date.name == '付款单' ? this.wenjian_list.map(item => item.name).join(',') : null,
                payment_data: this.date.name == '付款单' ? this.wenjian_list.map(item => item.wenjian.xin).join(' ') : null,
                payment_type: this.date.name == '付款单' ? this.wenjian_list.map(item => item.wenjian.lei).join(' ') : null
              }
            },
            onUploadProgress: progressEvent => {
              var complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
              // this.text = complete + '%'
              console.log(complete + '%')
            }
          }).then(res => {
            console.log(res)
            console.log(this.wenjian_list.map(item => item.wenjian.xin))
            if (res.data.code == 200) {
              this.$message({
                message: '上传成功',
                type: 'success'
              })
            } else if (res.data.code == 500) {}
          })
        }

        // upload_sale_mng_board_fj({data:{
        //     ent_id:this.$ent_id(),
        //     user_id:this.$jichuxinxi().user_id,
        //     file_no:this.date.date.file_no,
        //     con_data:this.date.name=='合同'?this.wenjian_list.map(item=>item.wenjian.xin).join(' '):null,
        //     con_type:this.date.name=='合同'?this.wenjian_list.map(item=>item.wenjian.lei).join(' '):null,
        //     deliver_data:this.date.name=='交付'?this.wenjian_list.map(item=>item.wenjian.xin).join(' '):null,
        //     deliver_type:this.date.name=='交付'?this.wenjian_list.map(item=>item.wenjian.lei).join(' '):null,
        //     inv_data:this.date.name=='发票'?this.wenjian_list.map(item=>item.wenjian.xin).join(' '):null,
        //     inv_type:this.date.name=='发票'?this.wenjian_list.map(item=>item.wenjian.lei).join(' '):null,
        //     rec_data:this.date.name=='回款'?this.wenjian_list.map(item=>item.wenjian.xin).join(' '):null,
        //     rec_type:this.date.name=='回款'?this.wenjian_list.map(item=>item.wenjian.lei).join(' '):null,
        // }},
        // promis
        // ).then(res=>{
        //     console.log(res)
        //     console.log(this.wenjian_list.map(item=>item.wenjian.xin))
        //     if(res.data.code==200){
        //         this.$message({
        //             message: '上传成功',
        //             type: 'success'
        //         });
        //     }else if(res.data.code==500){}
        // })
      } else {
        this.$message({
          message: '至少选择一个文件上传',
          type: 'warning'
        })
      }
    },
    quchu_xuanze_wenjian (index) {
      this.wenjian_list.splice(index, 1)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import '../../style/salesman.scss';
</style>
